<template>
    <v-container>
        <Form :item="item"/>
    </v-container>
    
</template>
<script>
import Form from '../../../../components/dashboard/festival/festival/Form';
import { HOME } from '../../../../routes';
export default {
    components: {
        Form
    },
    data() {
        return {
            item: {
                id: null,
                name: '',
                category: null,
                description: '',
                status: false,
                allowedProjects: [],
                registerDateLimit: null,
                avatar: null,
                srcFile: null,
                cartaz: null,
                criterias: [],
                judges: [],
                startingDate: null,
                festOpenDate: null,
                proSubsDate: null,
                lastSubsDate: null,
                nrmlSubsDate: null,
            }
        }
    },
    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
    }
}
</script>