<template>
  <div>
    <v-row>
      <v-col cols="12" sm="8" md="12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            label="critério"
            outlined
            style="width: 400px"
            dense
            v-model="criteria"
            :append-icon="criteria ? 'mdi-send' : ''"
            @click:append="addItem"
            @keydown.enter="addItem"
          ></v-text-field>
        </v-form>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <h2 class="body-1 success--text pl-3">
          Critérios&nbsp;
          <v-fade-transition leave-absolute>
            <span>
              {{ criterias_.length || 0 }}
            </span>
          </v-fade-transition>
        </h2>
      </v-col>
      
      <v-col>
        <v-alert type="error" v-if="error" outlined dense dismissible>
          {{ error }}
        </v-alert>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-data-table :headers="headers" :items="criterias_" :items-per-page="3" class="elevation-1">
          <template v-slot:[`item.status`]="{ item }">
            <v-switch v-model="item.status" dense :label="item.status ? 'Ativado' : 'Desativado'"></v-switch>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon color="info mr-2" small @click="editItem(item.name)" >
              mdi-pencil
            </v-icon>
            <v-icon color="error" small @click="removeItem(item.name)" >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: ["criterias"],

  data() {
    return {
      valid: true,
      criteria: null,
      error: null,
      headers: [
        { text: 'Critério', align: 'center', sortable: true, value: 'name' },
        { text: 'Estado', value: 'status' },
        { text: 'Remover', value: 'action', sortable: false }
      ],
    }
  },

  computed: {
    criterias_: {
      get() { return this.criterias; },
      set(val) { this.$emit("criterias_", val); }
    }
  },

  methods: {
    addItem() {
      if (this.$refs.form.validate()) {
        const index = _.findIndex(this.criterias_, ['name', this.criteria]);
        if (index == -1) {
          this.criterias_.push({ name: this.criteria, status: true });
          this.criteria = null;
        }
        else
          this.error = 'Critério já foi adicionado uma vez.';
      }
    },

    removeItem(name) {
      const index =_.findIndex(this.criterias_, [ 'name', name ]);

      /* if (item.key) {
          this.$store.dispatch('country/removeCity', { id: item.key })
      } */
      this.criterias_.splice(index, 1);
    },

    /**
     * @description edit a criteria
     */
    editItem(name) {
      this.criteria = name;
      this.removeItem(name);
    },
  }
}
</script>