<template>
    <div>
        <v-row>
            <v-col cols="12" sm="8" md="12">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                    <v-row align="center">
                        <v-col
                            class="d-flex"
                            cols="12"
                            sm="6"
                        >
                            <v-autocomplete
                                v-model="judge.name"
                                :items="judges()"
                                dense
                                chips
                                label="Select"
                                outlined
                                item-text="name"
                                item-value="id"
                                :append-icon="judge.name ? 'mdi-send' : ''"
                                @click:append="addItem"
                                @keydown.enter="addItem"
                                :rules="judgeRules"
                            >
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col
                            class="d-flex"
                            cols="12"
                            sm="6"
                        >
                             <v-select
                                :items="categories"
                                label="categoria de júri"
                                v-model="judge.category"
                                item-text="name"
                                item-value="key"
                                :rules="[v => !!v || 'Atribui uma categoria a júri']"
                                dense
                                outlined
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>

            <v-col cols="12" sm="6" md="4">
                <h2 class="body-1 success--text pl-3">
                Jurados&nbsp;
                <v-fade-transition leave-absolute>
                    <span>
                        {{ _judges.length || 0 }}
                    </span>
                </v-fade-transition>
                </h2>
            </v-col>
            
            <v-col>
                <v-alert type="error" v-if="error" outlined dense dismissible>
                    {{ error }}
                </v-alert>
            </v-col>

            <v-col
                cols="12"
                sm="12"
                md="12"
            >
                <v-data-table
                    :headers="headers"
                    :items="_judges"
                    :items-per-page="3"
                    class="elevation-1"
                >
                    <template v-slot:[`item.action`]="{ item }">
                        <v-icon color="info mr-2" small @click="editItem(item)" >
                            mdi-pencil
                        </v-icon>
                        <v-icon color="error" small @click="removeItem(item.id)" >
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import _ from 'lodash';

import { mapState } from 'vuex';

export default {
    props: ["judges_"],

    data() {
        return {
            categories: [
                {
                    name: 'Auxiliar',
                    key: 'Auxiliar'
                },
                {
                    name: 'Principal',
                    key: 'Principal'
                },
                {
                    name: 'Presidente',
                    key: 'Presidente'
                },
                {
                    name: 'Júri 2',
                    key: 'Jury_2'
                }
            ],
            valid: true,
            judge: {
                name: null,
                email: null,
                category: null,
                id: null
            },
            judgeRules: [
                v => !!v || 'Selecione um júri'
            ],
            error: null,
            headers: [
                {
                    text: 'Jurados',
                    align: 'center',
                    sortable: true,
                    value: 'name'
                },
                {
                    text: 'Categoria',
                    align: 'center',
                    value: 'category'
                },
                { text: 'Remover', value: 'action', sortable: false }
            ],
        }
    },

    computed: {
        _judges: {
            get() { return this.judges_; },
            set(val) { this.$emit("judges_", val); }
        }
    },

    methods: {
        ...mapState('dashboard/myFestival', ['judges']),

        addItem() {
            if (this.$refs.form.validate()) {
                const index = _.findIndex(this._judges, ['id', this.judge.name]);
                if (index == -1) {
                    const judge = _.find(this.judges(), ['id', this.judge.name]);
                    this._judges.push({ ...judge, category: this.judge.category });
                    this.judge = {
                        name: null,
                        email: null,
                        category: null,
                        id: null
                    };
                    this.$refs.form.resetValidation()
                }
                else
                    this.error = 'Juri já foi adicionado uma vez.';
            }
        },

        removeItem(id) {
            const index =_.findIndex(this._judges, [ 'id', id ]);

            /* if (item.key) {
                this.$store.dispatch('country/removeCity', { id: item.key })
            } */
            this._judges.splice(index, 1);
        },

        /**
         * @description edit a criteria
         */
        editItem(item) {
            Object.assign(this.judge, item)
            this.removeItem(item.id);
        },
    }
}
</script>