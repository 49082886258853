<template>
    <v-card class="secondary">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-alert
                        color="#171717"
                        border="left"
                        colored-border
                        flat
                        class="body-2"
                        icon="mdi-calendar-heart"
                    >
                        <span v-if="item.id">Atulizar Festival.</span>
                        <span v-else>Registar novo Festival.</span>
                    </v-alert>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12" md="12" sm="12">
                    <v-alert
                        v-for="(alert, i) in alerts" :key="i" 
                        dense text 
                        :type="`${ alert.success ? 'success': 'error' }`" 
                        dismissible>
                        {{ alert.message }}
                    </v-alert>
                </v-col>
            </v-row>

            <v-form
                ref="form"
                v-model="valid"
                :lazy-validation="lazy">

                <v-row>
                    <v-col md="4" sm="6" xl="3">
                        <v-text-field
                            label="Nome"
                            placeholder="Nome"
                            v-model="model.name"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>

                    <v-col md="4" sm="6" xl="3">
                        <v-file-input
                            accept="jpeg,jpg,png,webp"
                            placeholder="Escolhe um ficheiro"
                            label="Upload Cartaz"
                            :rules="cartazRules"
                            hint="800 x 566 pixels. PNG ou JPG com tamanho no máximo 2MB"
                            v-model="model.cartaz"
                            required
                            show-size
                            outlined
                            clearable
                            dense
                        ></v-file-input>
                    </v-col>

                    <v-col md="4" sm="6" xl="3">
                        <v-file-input
                            accept="jpeg,jpg,png,webp"
                            placeholder="Escolhe um ficheiro"
                            label="Upload Banner"
                            hint="1350 x 360 pixels. PNG ou JPG com tamanho no máximo 2MB"
                            :rules="bannerRules"
                            v-model="model.avatar"
                            show-size
                            outlined
                            clearable
                            dense
                        ></v-file-input>
                    </v-col>

                    <v-col md="12" sm="6" xl="3">
                        <v-file-input
                            accept="jpeg,jpg,png,webp"
                            placeholder="Escolhe um ficheiro"
                            label="Upload Fotos"
                            hint="900 x 600 pixels. PNG ou JPG com tamanho no máximo 2MB"
                            :rules="filerules"
                            v-model="model.srcFile"
                            show-size
                            outlined
                            clearable
                            dense
                        ></v-file-input>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-text-field
                            label="Local do Festival"
                            placeholder="Local do Festival"
                            v-model="model.location"
                            prepend-inner-icon="mdi-map-marker"
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="7">
                        <v-row>
                            <v-col cols="12">
                                <label>Descrição</label>
                                    <div class="black-text">
                                        <ckeditor
                                            height="400px"
                                            :editor="editor"
                                            v-model="model.description"
                                            :config="editorConfig"></ckeditor>
                                    </div>
                            </v-col>

                            <v-col cols="12">
                                <Criteria
                                    :criterias="model.criterias"
                                    v-on:criterias_="val => model.criterias = val"
                                />
                            </v-col>

                            <v-col cols="12">
                                <Judges
                                    :judges_="model.judges"
                                    v-on:judges_="val => model.judges = val"
                                />
                            </v-col>

                            <v-col class="d-flex" cols="12" sm="12">
                                <v-select
                                    v-model="model.category"
                                    :items="categories"
                                    label="Categoria"
                                    item-text="title"
                                    item-value="id"
                                    return-object
                                    dense
                                    outlined></v-select>
                            </v-col>

                            <v-col class="d-flex" cols="12">
                                <v-select
                                    v-model="model.allowedProjects"
                                    :items="subSubCategories"
                                    label="Tipos de Obras Permitidas"
                                    item-text="title"
                                    item-value="id"
                                    persistent-hint
                                    chips
                                    return-object
                                    single-line
                                    multiple
                                    dense
                                    outlined>
                                    <template v-slot:item="{ item: {title, description}}">
                                        {{title}} ({{description}})
                                    </template>    
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col cols="4">
                        <v-subheader class="text-primary">CALENDÁRIO FESTIVAL</v-subheader>
                        <v-row>
                            <v-col cols="12">
                                <StartingDate
                                    :date="model.startingDate"
                                    label="Data do evento"
                                    v-on:date_="val => model.startingDate = val"
                                />
                            </v-col>

                            <v-col cols="12">
                                <NrmlSubsDate
                                    :date="model.nrmlSubsDate"
                                    label="Prazo inscrição normal"
                                    v-on:date_="val => model.nrmlSubsDate = val"
                                />
                            </v-col>

                            <v-col cols="12">
                                <FestOpenDate
                                    :date="model.festOpenDate"
                                    label="Data do abertura"
                                    v-on:date_="val => model.festOpenDate = val"
                                />
                            </v-col>

                            <v-col cols="12">
                                <RegisterDateLimit
                                    :date="model.registerDateLimit"
                                    label="Prazo inscrição antecipada"
                                    v-on:date_="val => model.registerDateLimit = val"
                                />
                            </v-col>

                            <v-col cols="12">
                                <LastSubsDate
                                    :date="model.lastSubsDate"
                                    label="Prazo ultimas inscrições"
                                    v-on:date_="val => model.lastSubsDate = val"
                                />
                            </v-col>

                            <v-col cols="12">
                                <ProSubsDate
                                    :date="model.proSubsDate"
                                    label="Prazo prorrogado até"
                                    v-on:date_="val => model.proSubsDate = val"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-switch 
                            v-model="model.status" 
                            :label="`${model.status ? 'Ativo' : 'Desativado'}`"></v-switch>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col>
                        <v-btn 
                            :loading="processingFestival" :disabled="processingFestival" 
                            depressed color="primary" @click="add">
                            <span class="black--text">Submeter</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        
        </v-container>
    </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorDefaultConfig } from '../../../../utils';
import _ from 'lodash';
import moment from 'moment';

import StartingDate from './DatePicker';
import FestOpenDate from './DatePicker';
import RegisterDateLimit from './DatePicker';
import ProSubsDate from './DatePicker';
import LastSubsDate from './DatePicker';
import NrmlSubsDate from './DatePicker';
import Criteria from './Criterias';
import Judges from './Judges';

import * as file from '../../../../utils/file';

export default {
    components: {
        StartingDate,
        FestOpenDate,
        RegisterDateLimit,
        ProSubsDate,
        LastSubsDate,
        NrmlSubsDate,
        Criteria,
        Judges
    },
    props: {
        item: Object,
    },
    created() {
        this.model = _.cloneDeep({ ...this.item, status: this.item.status });
        this.fetchCategories({});
        this.listSubSubCategory({});
        this.getJudges();
    },
    data() {
        return {
            cartaz: null,
            valid: true,
            lazy: false,
            model: {
                id: null,
                name: '',
                category: null,
                description: '',
                status: false,
                allowedProjects: [],
                criterias: [],
                judges: [],
                registerDateLimit: null,
                location: null,
                startingDate: null,
                festOpenDate: null,
                proSubsDate: null,
                lastSubsDate: null,
                nrmlSubsDate: null,
                avatar: null,
                cartaz: null,
                srcFile: null,
            },
            alerts: [],
            rules: [
                v => {
                    if (!v  && !this.item.id) {
                        return 'Campo é de preenchimento obrigatório';
                    }
                    return true;
                },
                //value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            cartazRules: [
                v => {
                    if (!v) { 
                        if (this.model.id) return true;
                        return 'Campo é de preenchimento obrigatório';
                    }
                    else {
                        if (v.size > 2000000) return 'Só são permitidos imagens com até 2 MB!';

                        let img = new Image()
                        img.src = window.URL.createObjectURL(v);
                        let res = img.onload = function() {
                            console.log(img.width + " " + img.height) 
                            if (img.width != 800 || img.height!= 566)
                                return 'imagem cartaz tem que ter dimensão 800x566';
                            return true;
                        }();
                        return res;
                    }
                }
            ],
            bannerRules: [
                v => {
                    if (!v) return true;
                    else {
                        if (v.size > 2000000) return 'Só são permitidos imagens com até 2 MB!';

                        let img = new Image()
                        img.src = window.URL.createObjectURL(v);
                        let res = img.onload = function() {
                            console.log(img.width + " " + img.height) 
                            if (img.width != 1350 || img.height!= 360)
                                return 'imagem cartaz tem que ter dimensão 1350x360';
                            return true;
                        }();
                        return res;
                    }
                }
            ],
            file2MBrules: [
                v => {
                    if (!v  && !this.item.id) {
                        return 'Campo é de preenchimento obrigatório';
                    }
                    return true;
                },
                
                //file.factoryRule({ totalSize: { max: 2, unit: 'MB' } }),
            ],
            filerules: [
                file.factoryRule({  totalSize: { max: 2, unit: 'MB' } }),
            ],
            requiredRules: [
                v => !!v || 'Campo é de preenchimento obrigatório',
            ],
            editor: ClassicEditor,
            editorConfig: CKEditorDefaultConfig,
        }
    },
    watch: {
        cartaz(val) {
            let img = new Image()
            img.src = window.URL.createObjectURL(val);
            let res = img.onload = function() {
                console.log(img.width + " " + img.height) 
                if (img.width != 800 || img.height!= 566)
                    return 'imagem cartaz tem que ter dimensão 800x566'
            }();
            console.log("Res: ", res)
        }
    },
    computed: { 
        ...mapState('festival', ['categories']),
        ...mapState('project', ['subSubCategories']),
        ...mapState('dashboard/myFestival',[ 'processingFestival', 'loading' ]),
    },

    methods: {
        ...mapActions('dashboard/myFestival', ['createOrUpdate',]),
        ...mapActions('project', ['listSubSubCategory',]),
        ...mapActions('dashboard/myFestival', ['getJudges']),
        ...mapActions('festival', {
            fetchCategories: 'list',
        }),
        add() {
            this.alerts = [];
            //  && this.compareDate(this.model.registerDateLimit, this.model.startingDate)
            if (this.model.registerDateLimit && this.model.startingDate) {
                let isdate = this.compareDate(this.model.registerDateLimit, this.model.startingDate)
                if (!isdate) return
            }

            if (this.$refs.form.validate()) {
                this.createOrUpdate({data: this.model, token: this.$user.info}).then((data) => {
                    this.alerts = data.messages;
                    if (!this.item.id) {
                        if (data.success) {
                            this.model.id = null;
                            this.model.description = '';
                            this.$refs.form.reset();
                        } else if (data.data) {
                            this.model.id = data.data.id;
                        }
                    }
                }).catch(() => {
                    this.alerts = [{
                        success: false,
                        message: 'Problema no processamento'
                    }];
                });
            }
            
        },

        //TODO compare all the dates here
        compareDate(nrmlSubsDate, startingDate) {
            const rgd = moment.utc(nrmlSubsDate, 'YYYY-MM-DD');
            const std = rgd.isBefore(moment(startingDate)); 
            
            if (!std) {
                this.alerts = [{
                    success: false,
                    message: 'Data Inicio não pode ser antes do fim da subscrição'
                }];
                return false;
            }
            return true;
        }
    }
}
</script>